.ingredients-module {
    background: $medium-gray;
    text-align: center;
    padding-top: 0;

    @include breakpoint(xlarge) {
        padding: 100px 0;
    }

    &__body {
        max-width: 985px;
        margin: 0 auto 85px auto;

        @include breakpoint(large down) {
            padding: 25px 0;
            margin: 0;
        }

        .ingredients-module__text {
            &::after {
                display: none;
            }
        }

    }

    @include breakpoint(large) {
        &__row {
            &.-reverse {
                .cell:first-child {
                    order: 2;
                }
            }
        }
    }

    &__text {
        position: relative;
        overflow: hidden;
        padding: 25px;

        @include breakpoint(xlarge) {
            padding: 90px;
        }

        &::after {
            @include animation-setup-text;

            .-light-green & {
                background-color: darken($light-green,5%);
            }

            .-medium-green & {
                background: darken($medium-green,5%);
            }

            .-dark-green & {
                background: darken($dark-green,5%);
            }

            .reveal & {
                @include animation-setup-reveal;
            }

        }
    }

    &__labels {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        color: $white;
        text-shadow: 0 0 6px rgba(#000, 0.4);
        font-weight: 900;
        background-size: cover;
        background-position: center center;
        padding: 50px 0;
        overflow: hidden;
        position: relative;

        &::after {
            @include animation-setup-image;

            .reveal & {
                @include animation-setup-reveal;
                transition-delay: .15s;
            }

        }

    }

    &__ingredients {
        font-size: 19px;
        position: relative;
        padding-left: 35px;

        &:before {
            content: '';
            width: 30px;
            height: 28px;
            background: url('../Frontend/images/icon__leaf.png');
            position: absolute;
            left: 0;
            top: -4px;
        }
    }

    &__ingredient {
        font-size: 80px;
        display: block;
        line-height: 1;

        @include breakpoint(large) {
            font-size: 85px;
        }

        @include breakpoint(xlarge) {
            font-size: 124px;
        }
    }
}
