.page-footer {
    text-align: center;
    padding: 100px 0 130px 0;
    font-size: 14px;
    color: #b6bdbb;

    &__icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: $dark-gray;
        display: flex;
        font-size: 30px;
        color: $white;
        align-items: center;
        justify-content: center;
        transition: all ease-in-out 300ms;
    }

    p {
        font-weight: bold;
        line-height: 2;
    }

    ul {
        list-style: none;
        margin: 0;
        display: flex;
        justify-content: center;

        &.page-footer__socials {
            margin-bottom: 40px;

            a:hover {
                .page-footer__icon {
                    background: darken($dark-gray, 10%);
                }
            }
        }

        &.page-footer__corp {
            margin-top: 65px;
        }

        li {
            margin: 0 10px;
        }
    }

    a {
        color: #b6bdbb;
        transition: all ease-in-out 300ms;

        &:hover {
            color: darken($dark-gray, 25%);
        }
    }
}
