@charset "UTF-8";

@font-face {
    font-family: 'Carlsberg';
    src: url('/assets/fonts/CarlsbergSansLight.ttf');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Carlsberg';
    src: url('/assets/fonts/CarlsbergSansLightItalic.ttf');
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: 'Carlsberg';
    src: url('/assets/fonts/CarlsbergSansLight.ttf');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Carlsberg';
    src: url('/assets/fonts/CarlsbergSansLightItalic.ttf');
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: 'Carlsberg';
    src: url('/assets/fonts/CarlsbergSansBold.ttf');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Carlsberg';
    src: url('/assets/fonts/CarlsbergSansBoldItalic.ttf');
    font-style: italic;
    font-weight: 700;
}

@font-face {
    font-family: 'Carlsberg';
    src: url('/assets/fonts/CarlsbergSansBlack.ttf');
    font-style: normal;
    font-weight: 900;
}

@font-face {
    font-family: 'Carlsberg';
    src: url('/assets/fonts/CarlsbergSansBlackItalic.ttf');
    font-style: italic;
    font-weight: 900;
}

// Fontastic

@font-face {
    font-family: "visitcarlsberg";
    src: url("/assets/fonts/visitcarlsberg.eot");
    src: url("/assets/fonts/visitcarlsberg.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/visitcarlsberg.woff") format("woff"), url("/assets/fonts/visitcarlsberg.ttf") format("truetype"), url("/assets/fonts/visitcarlsberg.svg#visitcarlsberg") format("svg");
    font-weight: normal;
    font-style: normal;
}

[data-icon]:before {
    font-family: "visitcarlsberg" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "visitcarlsberg" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-angle-double-down:before {
    content: "\61";
}

.icon-angle-down:before {
    content: "\62";
}

.icon-clock-o:before {
    content: "\63";
}

.icon-calendar-o:before {
    content: "\64";
}

.icon-ticket:before {
    content: "\65";
}

.icon-angle-double-right:before {
    content: "\66";
}

.icon-angle-up:before {
    content: "\67";
}

.icon-angle-right:before {
    content: "\68";
}

.icon-facebook:before {
    content: "\69";
}

.icon-twitter:before {
    content: "\6a";
}

.icon-instagram:before {
    content: "\6b";
}

.icon-angle-left:before {
    content: "\6c";
}

.icon-android-time:before {
    content: "\6d";
}

.icon-linkedin:before {
    content: "\6e";
}

.icon-upload-1:before {
    content: "\6f";
}

.icon-carlsberg-leaf-black:before {
    content: "\70";
}
