.animate-fade-in {
    @include mui-fade(in, $duration: 10s);
}

@mixin animation-setup-image {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: #fff;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.9, 0, 0.1, 1);
    transition: -webkit-transform 0.8s cubic-bezier(0.9, 0, 0.1, 1);
    -o-transition: transform 0.8s cubic-bezier(0.9, 0, 0.1, 1);
    transition: transform 0.8s cubic-bezier(0.9, 0, 0.1, 1);
    transition: transform 0.8s cubic-bezier(0.9, 0, 0.1, 1), -webkit-transform 0.8s cubic-bezier(0.9, 0, 0.1, 1);
    background: url(../Frontend/images/icon__leaf.svg) white;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50px;
}

@mixin animation-setup-text {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.9, 0, 0.1, 1);
    transition: -webkit-transform 0.8s cubic-bezier(0.9, 0, 0.1, 1);
    -o-transition: transform 0.8s cubic-bezier(0.9, 0, 0.1, 1);
    transition: transform 0.8s cubic-bezier(0.9, 0, 0.1, 1);
    transition: transform 0.8s cubic-bezier(0.9, 0, 0.1, 1), -webkit-transform 0.8s cubic-bezier(0.9, 0, 0.1, 1);
}

@mixin animation-setup-reveal {
    -webkit-transform: translate3d(101%, 0, 0);
    transform: translate3d(101%, 0, 0);
}

body {

    #load {
        position: fixed;
        z-index: 99;
        width: 100%;
        height: 110%;
        background: #f4f6f5f5;
        margin-top: -15px;
        svg {
            &#leaf {
                width: 125px;
                overflow: visible;
                top: 50%;
                left: 50%;
                position: absolute;
                transform: translateX(-50%) translateY(-50%);
                padding: 20px;
                margin: -55px 0 0 0
            }
        }
    }

    &.after-intro {
        overflow: auto;

        #load {
            height: 0;
            transition: ease-in-out 1s;
            transition-delay: .2s;

            svg, .progressbar-text {
                margin-top: -100px;
                opacity: 0;
                transition: ease-in-out .5s;
            }

        }
    }

}