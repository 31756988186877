.hero-module {
    background: $medium-gray;
    text-align: center;

    .grid-container {
        padding: 30px 0;
        background: url(../Frontend/images/background__leaf.png) center right no-repeat;
        background-size: 50% auto;
        background-position: right center;

        @include breakpoint(medium) {
            padding: 60px 0 80px 0;
        }

        @include breakpoint(xlarge) {
            padding: 70px 0 115px 0;
            background-size:auto 120%;
            background-position: right center;
        }

    }

    &__leaf {
        max-width: 55px;
    }

    &__body {
        font-size: 26px;
        max-width: 985px;
        margin: 0 auto;
        padding: 0 10px;

        p {
            font-size: 1rem;
            margin: 0;
            line-height: 1.5;
        }

        @include breakpoint(medium) {
            padding: 0 50px;

            h1 {
                font-size: 2.5rem;
            }

        }

        @include breakpoint( xlarge) {
            padding: 0;

            h1 {
                font-size: 4.70588rem;
            }

            p {
                font-size: inherit;
            }

        }

    }
}
