@include breakpoint(large down) {
    section .grid-container {
        padding: 0;
    }
}

.page-container {
    padding-top: 129px;
    @include breakpoint(medium down) {
        padding-top: 0;
    }
}

.scroll-to-top {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: right;
    transition: transform ease-in-out 350ms;
    transform: translateY(100%);
    z-index: 11;
    display: none;

    @include breakpoint(medium) {
        display: block;
    }

    .scroll & {
        transform: translateY(10px);
    }

    &:hover {
        //transform: translateY(0);
    }

    a {
        display: inline-block;
        background: $dark-green;
        line-height: 1;
        font-size: 12px;
        font-weight: bold;
        position: relative;
        text-transform: uppercase;
        transition: background ease-in-out 300ms;
        border-bottom: 0;
        color: white;
        border-radius: 0;
        padding: 14px 20px 22px;
        transition: all ease-in-out 350ms;

        &:hover {
            background: darken($dark-green, 10%);
            padding: 14px 20px 26px;
            transition: all ease-in-out 350ms;
        }

        &:active {
            background: darken($dark-green, 15%);
            padding: 14px 20px 30px;
            transition: all ease-in-out 150ms;
        }

    }
}