.page-header {

    @include breakpoint(large up) {
        position: fixed;
        width: 100%;
        top: 0;
        background: white;
        z-index: 9;

        &.fixed {
            .page-header__logo {
                margin: 20px 0;
                max-width: 100px;
                transition: all ease-in-out 300ms;
            }
        }

    }

    &__logo {
        max-width: 120px;
        margin: 30px auto;
        transition: all ease-in-out 300ms;

        @include breakpoint(medium) {
            margin: 30px 0;
        }
    }

    .cell {
        align-items: center;
        display: flex;
    }

    nav {
        width: 100%;
    }

    ul {
        margin: 0;
        list-style: none;
        display: flex;
        justify-content: flex-end;

        li {
            margin: 0 10px;
            text-transform: uppercase;
            font-size: 14px;

            a {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}