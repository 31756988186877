.nutrition-module {
    text-align: center;
    padding-top: 50px;

    @include breakpoint(medium) {
        padding: 100px 0;
    }

    &__body {
        max-width: 985px;
        margin: 0 auto;
        overflow: hidden;

        @include breakpoint(medium) {
            margin-bottom: 85px;
        }

        @include breakpoint(large down) {
            padding: 0 15px;
        }

        .ingredients-module__text {
            &::after {
                display: none;
            }
        }

    }

    &__container {
        position: relative;
        overflow: hidden;

        .cell {
            z-index: 2;
        }

        img {
            opacity: .97;
        }

    }

    &__arrow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: none;
        padding-right: 25px;
        transition: all ease-in-out 500ms;

        @include breakpoint(medium) {
            display: block;
        }

        @include breakpoint(xlarge) {
            padding-right: 0;
        }
    }

    ul {
        list-style: none;
        margin: 0;

        @include breakpoint(small only) {
            margin-bottom: 50px;
        }
    }

    &__sort {
        font-size: 26px;
        font-weight: bold;

        @include breakpoint(large) {
            font-size: 32px;
        }
    }

    &__percentage {
        font-size: 22px;

        @include breakpoint(large) {
            font-size: 27px;
        }
    }

    &__kcal {
        font-size: 50px;
        font-weight: 900;
        color: #7ca39f;

        @include breakpoint(large) {
            font-size: 62px;
        }
    }
}
