.-light-green {
    background: $light-green;
    color: $white;
}

.-medium-green {
    background: $medium-green;
    color: $white;
}

.-dark-green {
    background: $dark-green;
    color: $white;
}