.grid-module {
    background: $medium-gray;

    &.-three-icons {
        padding: 50px 0;

        @include breakpoint(medium) {
            text-align: center;
        }

        .cell {
            overflow: hidden;
            position: relative;

            @include breakpoint(medium only) {
                padding: 0 25px;
            }

            @include breakpoint(small only) {
                text-align: center;

                + .cell {
                    margin-top: 50px;
                }
            }
        }
    }

    @include breakpoint(medium) {
        &.-bottom-padding {
            padding-bottom: 100px;
        }
    }

    &__content-box {
        display: flex;
        flex-direction: column;
        height: 100%;

        @include breakpoint(medium) {
            &.-reverse {
                .grid-module__image {
                    order: 2;
                }
            }
        }
    }

    // Stupid IE hack
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        &__image {
            overflow: hidden;

            img {
                max-width: none;
            }
        }
    }

    &__image {
        position: relative;
        overflow: hidden;

        &::after {
            @include animation-setup-image;

            .reveal & {
                @include animation-setup-reveal;
                transition-delay: .45s;
            }

        }
    }

    &__text {
        padding: 15px;
        flex-grow: 1;
        position: relative;
        overflow: hidden;

        @include breakpoint(medium) {
            padding: 25px;
        }

        @include breakpoint(xlarge) {
            padding: 50px;
        }

        &::after {
            @include animation-setup-text;

            .-light-green & {
                background-color: darken($light-green,5%);
            }

            .-medium-green & {
                background: darken($medium-green,5%);
            }

            .-dark-green & {
                background: darken($dark-green,5%);
            }

            .reveal & {
                @include animation-setup-reveal;
            }

        }

    }

    &__column {
        height: 100%;
        display: flex;
        flex-direction: column;

        div {
            padding: 15px;

            @include breakpoint(medium) {
                padding: 25px;
            }

            @include breakpoint(xlarge) {
                padding: 50px;
            }

            &:last-child {
                flex-grow: 1;
            }
        }
    }

    &__row {
        .cell {
            padding: 15px;
            background-size: cover;

            @include breakpoint(medium) {
                padding: 25px;
            }

            @include breakpoint(xlarge) {
                padding: 50px;
            }

        }
    }

    .-background-image {
        min-height: 344px;
        background-position: center center;
        overflow: hidden;
        position: relative;

        &::after {
            @include animation-setup-image;
        }

        &.reveal {
            &::after {
                @include animation-setup-reveal;
                transition-delay: .45s;
            }
        }

    }

    .-text {
        overflow: hidden;
        position: relative;

        &::after {
            @include animation-setup-text;
        }

        &.-light-green {
            &::after {
                background-color: darken($light-green,5%);
            }
        }

        &.-medium-green {
            &::after {
                background: darken($medium-green, 5%);
            }
        }

        &.-dark-green {
            &::after {
                background: darken($dark-green, 5%);
            }
        }

        &.reveal {
            &::after {
                @include animation-setup-reveal;
            }
        }

    }

}
